<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title" v-if="!warehouseInformation">
      Agregar Almacén
    </h1>

    <!--
    <div class="control add-global-controls">
      <b-button
        type="is-primary"
        class="special-margin"
        @click="openAddWarehousePhones()"
        >{{
          warehouseInformation
            ? "Ver teléfonos del almacén"
            : "Agregar teléfonos del almacén"
        }}</b-button
      >
    </div>
    -->

    <div v-if="!warehouseInformation" class="global-form-hint-container">
      <p class="global-form-hint">Los campos requeridos tienen el marco</p>
      <span class="global-form-hint-color"> Morado </span>
    </div>

    <!--
      <b-field grouped class="special-margin">
        <b-switch v-model="newWarehouse.ALM_DIST" true-value="S" false-value="N">
          Almacén para centro de distribución
        </b-switch>
        <b-switch v-model="newWarehouse.TIPO_ALM" true-value="S" false-value="N">
          Almacén para venta
        </b-switch>
         <b-switch v-model="newWarehouse.NO_DUP_ADE" true-value="S" false-value="N">
          Artículos duplicados en ticket
        </b-switch>
      </b-field>
      -->

    <h3 class="form-division">Información general</h3>

    <b-field grouped class="special-margin">
      <b-field
        label="Clave del almacén (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': warehouseRequiredErrors.CLAVE_ALMACEN }"
        :message="{
          'La clave de almacén no es valida':
            warehouseRequiredErrors.CLAVE_ALMACEN,
        }"
      >
        <b-input
          :disabled="warehouseInformation"
          placeholder="Ejemplo: 5EPG"
          expanded
          icon="warehouse"
          v-model="newWarehouse.CLAVE_ALMACEN"
          required
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Nombre del almacén o sucursal (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': warehouseRequiredErrors.NOMBRE_ALM }"
        :message="{
          'El nombre del almacén no es valido':
            warehouseRequiredErrors.NOMBRE_ALM,
        }"
      >
        <b-input
          placeholder="Ejemplo: Almacén Queretaro"
          expanded
          icon="pencil"
          v-model="newWarehouse.NOMBRE_ALM"
          required
        ></b-input>
      </b-field>
      <b-field
        label="Descripción del almacén o sucursal (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: Almacén de productos verdes"
          expanded
          icon="lead-pencil"
          v-model="newWarehouse.DESCRIP_BREV"
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Tipo de almacén (Requerido)"
        expanded
        label-position="inside"
        class="required-input"
        required
      >
        <b-select
          placeholder="Ejemplo: Producto"
          expanded
          v-model="newWarehouse.TIPO"
          icon="call-merge"
        >
          <option value="AL">Almacén</option>
          <option value="DE">Almacén de defectuosos</option>
          <option value="VI">Almacén virtual</option>
        </b-select>
      </b-field>
      <b-field label="Serie (Opcional)" expanded label-position="inside">
        <b-input
          placeholder="Ejemplo: GE"
          expanded
          icon="code-array"
          v-model="newWarehouse.SERIE"
        ></b-input>
      </b-field>
    </b-field>

    <b-checkbox
      class="permissions-checkbox first-row-checkbox special-margin"
      true-value="S"
      false-value="N"
      v-model="newWarehouse.PERMITE_NEGATIVO"
    >
      Permite inventarios negativos en ventas
    </b-checkbox>

    <h3 class="form-division">Direccion</h3>

    <b-field grouped class="special-margin">
      <b-field expanded label-position="inside">
        <template slot="label">
          Código Postal (Opcional)
          <b-tooltip
            multilined
            label="Al llenar el código postal la información de estado, municipio, ciudad y colonia es autocompletada"
          >
            <b-icon
              size="is-small"
              icon="help-circle-outline"
              custom-class="input-help-icon"
            ></b-icon>
          </b-tooltip>
        </template>
        <b-input
          placeholder="Ejemplo: 00000"
          expanded
          icon="directions-fork"
          v-model="newWarehouse.CP_ALM"
          v-cleave="masks.customCP"
          @input="(value) => searchForDirectionInformation(value)"
          type="number"
          step="any"
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field label="Dirección (Opcional)" expanded label-position="inside">
        <b-input
          placeholder="Ejemplo: Paseo del árbol #229"
          expanded
          icon="file-outline"
          v-model="newWarehouse.DOMICIL_ALM"
        ></b-input>
      </b-field>
      <b-field label="Referencia (Opcional)" expanded label-position="inside">
        <b-input
          placeholder="Ejemplo: Casa azul"
          expanded
          icon="file-outline"
          v-model="newWarehouse.REFERENCIA"
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Número exterior (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 250"
          expanded
          icon="counter"
          v-model="newWarehouse.NUM_EXTER"
        ></b-input>
      </b-field>
      <b-field
        label="Número interior (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 10"
          expanded
          icon="counter"
          v-model="newWarehouse.NUM_INTER"
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field label="Municipio (Opcional)" expanded label-position="inside">
        <b-input
          placeholder="Ejemplo: Nuevo León"
          expanded
          icon="sign-real-estate"
          v-model="newWarehouse.NOMBRE_MUNICIPIO"
        ></b-input>
      </b-field>
      <b-field
        label="Clave de municipio (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 001"
          expanded
          icon="city-variant-outline"
          v-model="newWarehouse.MUNICIPIO_ALM"
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field label="Estado (Opcional)" expanded label-position="inside">
        <b-select
          placeholder="Ejemplo: Nuevo León"
          expanded
          icon="sign-real-estate"
          v-model="newWarehouse.ESTADO_ALM"
        >
          <option value="" disabled>Seleccione un estado</option>
          <option
            v-for="singleState in states"
            :value="singleState.CLAVE"
            :key="singleState.NOMBRE"
          >
            {{ singleState.NOMBRE }}
          </option>
        </b-select>
      </b-field>
      <b-field
        label="Clave de la localidad (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 01"
          expanded
          icon="sign-real-estate"
          v-model="newWarehouse.LOCALIDAD"
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field expanded label-position="inside">
        <template slot="label">
          Nombre de la Colonia (Opcional)
          <b-tooltip
            multilined
            label="El listado de colonias disponibles aparece una vez llenado el código postal"
          >
            <b-icon
              size="is-small"
              icon="help-circle-outline"
              custom-class="input-help-icon"
            ></b-icon>
          </b-tooltip>
        </template>
        <b-autocomplete
          :data="filteredColonies"
          field="colony"
          open-on-focus
          placeholder="Ejemplo: Del Norte"
          expanded
          icon="home-group"
          v-model="newWarehouse.NOMBRE_COLONIA"
          clearable
          @select="(option) => (newWarehouse.NOMBRE_COLONIA = option.colony)"
        >
          <template #empty>No se encontraron colonias</template>
        </b-autocomplete>
      </b-field>

      <b-field
        label="Clave de la colonia (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 0001"
          expanded
          icon="sign-real-estate"
          v-model="newWarehouse.COLONIA_ALM"
        ></b-input>
      </b-field>
    </b-field>

    <h3 class="form-division">Información de contacto</h3>

    <b-field grouped class="special-margin">
      <b-field label="Nombre (Opcional)" expanded label-position="inside">
        <b-input
          placeholder="Ejemplo: Pedro"
          expanded
          icon="account-box-outline"
          v-model="newWarehouse.NOMBRE"
        ></b-input>
      </b-field>
      <!--
      <b-field
        label="Nombre abreviado (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: Pedro"
          expanded
          icon="account-multiple-outline"
          v-model="newWarehouse.NOMB_ABREV"
        ></b-input>
      </b-field>
      -->
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Apellido Paterno (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: Rodriguez"
          expanded
          icon="account-multiple-outline"
          v-model="newWarehouse.PATER"
        ></b-input>
      </b-field>
      <b-field
        label="Apellido Materno (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: Pérez"
          expanded
          icon="account-child"
          v-model="newWarehouse.MATER"
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field label="Teléfono (Opcional)" expanded label-position="inside">
        <b-input
          placeholder="Ejemplo: 3300000000"
          expanded
          icon="phone"
          v-model="newWarehouse.TELS_ALM"
          v-cleave="masks.customPhone"
        ></b-input>
      </b-field>
      <b-field
        label="Teléfono celular (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 3300000000"
          expanded
          icon="cellphone"
          v-model="newWarehouse.CEL_ALM"
          v-cleave="masks.customPhone"
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field label="Email (Opcional)" expanded label-position="inside">
        <b-input
          placeholder="Ejemplo: compañia@mail.com"
          expanded
          icon="at"
          v-model="newWarehouse.EMAIL"
          type="email"
        ></b-input>
      </b-field>
    </b-field>

    <!--
      <h3 class="form-division">Costos de movimientos entre almacenes</h3>

      <b-field grouped class="special-margin">
        <b-switch v-model="shippingActive">
          Aplica flete
        </b-switch>
        <b-switch v-model="shippingTaxActive">
          Aplica IVA en el flete
        </b-switch>
        <b-switch v-model="insuranceActive">
          Aplica seguro
        </b-switch>
        <b-switch v-model="insuranceTaxActive">
          Aplica IVA en el seguro
        </b-switch>
        <b-switch v-model="movementCostActive">
          Aplica costo administrativo
        </b-switch>
      </b-field>

      <b-field grouped class="special-margin" v-if="shippingActive || shippingTaxActive">
        <b-field label="Porcentaje de flete (Opcional)" expanded label-position="inside" v-if="shippingActive">
            <b-input placeholder="Ejemplo: 5" expanded icon="bank" type="number" v-model="newWarehouse.PORC_FLETE"></b-input>
        </b-field>
        <b-field label="Porcentaje de IVA del flete (Opcional)" expanded label-position="inside" v-if="shippingTaxActive">
            <b-input placeholder="Ejemplo: 16" expanded icon="bank" type="number" v-model="newWarehouse.PORCIVAFLE"></b-input>
        </b-field>
      </b-field>

      <b-field grouped class="special-margin" v-if="insuranceActive || insuranceTaxActive">
        <b-field label="Porcentaje de seguro (Opcional)" expanded label-position="inside" v-if="insuranceActive">
            <b-input placeholder="Ejemplo: 2" expanded icon="bank" type="number" v-model="newWarehouse.PORC_SEGUR"></b-input>
        </b-field>
        <b-field label="Porcentaje de IVA del seguro (Opcional)" expanded label-position="inside" v-if="insuranceTaxActive">
            <b-input placeholder="Ejemplo: 16" expanded icon="bank" type="number" v-model="newWarehouse.PORCIVASEG"></b-input>
        </b-field>
      </b-field>

      <b-field grouped class="special-margin" v-if="movementCostActive">
        <b-field label="Porcentaje costo administrativo (Opcional)" expanded label-position="inside">
            <b-input placeholder="Ejemplo: 2" expanded icon="bank" type="number" v-model="newWarehouse.PORC_ADM"></b-input>
        </b-field>
      </b-field>

      <h3 class="form-division">Información de pago</h3>

      <b-field grouped class="special-margin">
        <b-field label="Cuenta Contable (Opcional)" expanded label-position="inside">
            <b-input placeholder="Ejemplo: 000000" expanded icon="bank" v-model="newWarehouse.CODIGO_C" v-cleave="masks.customAccount"></b-input>
        </b-field>
      </b-field>

      <b-field grouped class="special-margin">
        <b-field label="Cuenta Contable 2 (Opcional)" expanded label-position="inside">
            <b-input placeholder="Ejemplo: 000000" expanded icon="bank" v-model="newWarehouse.CODIGO_C2" v-cleave="masks.customAccount"></b-input>
        </b-field>
        <b-field label="Cuenta Contable 3 (Opcional)" expanded label-position="inside">
            <b-input placeholder="Ejemplo: 000000" expanded icon="bank" v-model="newWarehouse.CODIGO_C3" v-cleave="masks.customAccount"></b-input>
        </b-field>
      </b-field>

      -->

    <div class="add-global-controls" v-if="warehouseInformation">
      <b-button
        type="is-success"
        @click="saveWarehouse(1)"
        v-if="
          warehouseInformation.CLAVE_ALMACEN !== 'Transferencias' &&
          warehouseInformation.CLAVE_ALMACEN !== 'Virtual'
        "
        >Actualizar almacén</b-button
      >
      <b-button
        type="is-success"
        @click="activateUser()"
        v-if="
          warehouseInformation.STATUS === 'PE' &&
          warehouseInformation.CLAVE_ALMACEN !== 'Transferencias' &&
          warehouseInformation.CLAVE_ALMACEN !== 'Virtual'
        "
      >
        Activar almacén
      </b-button>

      <b-button
        type="is-danger"
        class="cancel-button"
        @click="disableWarehouse()"
        v-if="
          warehouseInformation.STATUS === 'AC' &&
          warehouseInformation.CLAVE_ALMACEN !== 'Transferencias' &&
          warehouseInformation.CLAVE_ALMACEN !== 'Virtual'
        "
      >
        Desactivar almacén
      </b-button>
    </div>

    <div class="add-global-controls" v-if="!warehouseInformation">
      <b-button type="is-success" @click="saveWarehouse(0)"
        >Crear almacén</b-button
      >
      <b-button type="is-danger" @click="closeModal()" class="cancel-button"
        >Regresar</b-button
      >
    </div>
  </div>
</template>

<script>
import AddWarehouseContact from "./AddWarehouseContact";
import Cleave from "cleave.js";
import Masks from "../../../data/cleave-masks";
import States from "../../../data/states";
import { validateFields, checkValidationErrors } from "../../../utils/fns";
import { EventBus } from "../../../event-bus";
import { fillObjectForm } from "../../../utils/component-fns";
// Cleave variable initialization to bind formats to inputs
const cleave = {
  name: "cleave",
  bind(el, binding) {
    const input = el.querySelector("input");
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector("input");
    input._vCleave.destroy();
  },
};

// @ is an alias to /src
export default {
  name: "AddWarehouseModal",
  props: ["warehouseInformation"],
  components: {},
  directives: { cleave },
  data() {
    return {
      typeSelected: [],
      newWarehouse: {
        CLAVE_ALMACEN: "",
        NOMBRE_ALM: "",
        DESCRIP_BREV: "",
        TIPO: "AL",
        DOMICIL_ALM: "",
        NUM_EXTER: "",
        NUM_INTER: "",
        REFERENCIA: "",
        CP_ALM: "",
        NOMBRE_COLONIA: "",
        COLONIA_ALM: "",
        ESTADO_ALM: "",
        LOCALIDAD: "",
        NOMBRE_MUNICIPIO: "",
        MUNICIPIO_ALM: "",
        TELS_ALM: "",
        CEL_ALM: "",
        EMAIL: "",
        PATER: "",
        MATER: "",
        NOMBRE: "",
        CODIGO_C: "",
        CODIGO_C2: "",
        CODIGO_C3: "",
        ALM_DIST: "S",
        TIPO_ALM: "S",
        NO_DUP_ADE: "S",
        PORC_FLETE: "",
        PORCIVAFLE: "",
        PORC_SEGUR: "",
        PORCIVASEG: "",
        PORC_ADM: "",
        PERMITE_NEGATIVO: "S",
        SERIE: "",
      },
      warehouseRequiredErrors: {
        CLAVE_ALMACEN: false,
        NOMBRE_ALM: false,
      },
      currentColonies: [],
      shippingActive: false,
      shippingTaxActive: false,
      insuranceActive: false,
      insuranceTaxActive: false,
      movementCostActive: false,
      states: States,
      masks: Masks,
    };
  },
  async mounted() {
    // Fill the warehouse information in form fields
    if (this.warehouseInformation) {
      // Fill the form with received information
      fillObjectForm(this.warehouseInformation, this.newWarehouse);
      this.newWarehouse._id = this.warehouseInformation._id;
    }

    EventBus.$on("sendWarehousePhones", (phones) => {
      this.newWarehouse.TELEFONOS = phones;
    });

    if (this.newWarehouse.CP_ALM.length === 5) {
      let response = await this.$store.dispatch("SEARCHDIRECTIONINFORMATION", {
        zipCode: this.newWarehouse.CP_ALM,
      });
      if (response.length > 0) {
        this.currentColonies = response;
      }
    }
  },
  beforeDestroy() {
    EventBus.$off();
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    /**
     *
     * @desc saves or updates a warehouse depending on type
     */
    async saveWarehouse(type) {
      try {
        this.warehouseRequiredErrors = validateFields(
          this.newWarehouse,
          this.warehouseRequiredErrors
        );
        if (checkValidationErrors(this.warehouseRequiredErrors)) {
          let response = await this.$store.dispatch(
            type === 0 ? "SAVEWAREHOUSE" : "EDITWAREHOUSE",
            this.newWarehouse
          );
          if (response === "Success") {
            this.deleteInformation();
            this.$buefy.dialog.confirm({
              title: "Éxito",
              message: "Se guardó exitosamente el almacén",
              confirmText: "Entendido",
              type: "is-success",
              hasIcon: true,
              onConfirm: () => {
                if (type === 0) this.closeModal();
              },
              cancelText: "cancelar",
              canCancel: false,
            });
          } else {
            this.$buefy.dialog.confirm({
              title: "Error",
              message:
                "Hubo un error al guardar el almacén en la base de datos",
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
          }
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al guardar el almacén en la base de datos revise los campos obligatorios",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al guardar el almacén en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    deleteInformation() {
      this.newWarehouse = {
        CLAVE_ALMACEN: "",
        NOMBRE_ALM: "",
        DESCRIP_BREV: "",
        TIPO: "AL",
        CP_ALM: "",
        CODIGO_C: "",
        CODIGO_C2: "",
        CODIGO_C3: "",
        ALM_DIST: "S",
        TIPO_ALM: "S",
        NO_DUP_ADE: "S",
        PORC_FLETE: "",
        PORCIVAFLE: "",
        PORC_SEGUR: "",
        PORCIVASEG: "",
        PORC_ADM: "",
        TELEFONOS: [],
      };
    },
    openAddWarehousePhones() {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddWarehouseContact,
        props: {
          warehousePhones: this.newWarehouse.TELEFONOS,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    /**
     * @desc searches for direction information when zip has the corret format
     */
    async searchForDirectionInformation(zipCode) {
      if (zipCode.length === 5) {
        let response = await this.$store.dispatch(
          "SEARCHDIRECTIONINFORMATION",
          { zipCode }
        );
        if (response.length > 0) {
          this.newWarehouse.ESTADO_ALM = response[0].state_code;
          this.newWarehouse.NOMBRE_MUNICIPIO = response[0].municipality;
          this.newWarehouse.MUNICIPIO_ALM = response[0].municipio_code;
          this.newWarehouse.LOCALIDAD = response[0].localidad_code;
          this.newWarehouse.NOMBRE_COLONIA = response[0].colony;
          this.newWarehouse.COLONIA_ALM = response[0].colony_code;
          this.currentColonies = response;
        }
      }
    },
    async activateUser() {
      try {
        let response = await this.$store.dispatch("ACTIVATEWAREHOUSES", {
          warehouseId: this.warehouseInformation._id,
        });
        if (response === "Success") {
          this.deleteInformation();
          this.$buefy.dialog.confirm({
            title: "Éxito",
            message: "Se activó exitosamente el almacén",
            confirmText: "Entendido",
            type: "is-success",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: "Hubo un error al activar el almacén en la base de datos",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al activar el almacén en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    async disableWarehouse() {
      try {
        let response = await this.$store.dispatch("DISABLEWAREHOUSES", {
          warehouseId: this.warehouseInformation._id,
        });
        if (response === "Success") {
          this.deleteInformation();
          this.$buefy.dialog.confirm({
            title: "Éxito",
            message: "Se desactivó exitosamente el almacén",
            confirmText: "Entendido",
            type: "is-success",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al desactivar el almacén en la base de datos",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al desactivar el almacén en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
  },
  computed: {
    filteredColonies() {
      return this.currentColonies.filter((singleColony) => {
        return (
          singleColony.colony
            .toString()
            .toLowerCase()
            .indexOf(this.newWarehouse.NOMBRE_COLONIA.toLowerCase()) >= 0
        );
      });
    },
    companyPaymentPlanModules() {
      if (
        this.$store.getters.COMPANY_INFORMATION &&
        this.$store.getters.COMPANY_INFORMATION.paymentPlan
      ) {
        return this.$store.getters.COMPANY_INFORMATION.paymentPlan.modules;
      }
      return this.$store.getters.COMPANY_INFORMATION.paymentPlan.modules;
    },
    permissions() {
      if (this.$store.getters.USER.permissions) {
        return this.$store.getters.USER.permissions.permissions;
      }
      return this.$store.getters.USER.permissions;
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
